<template>
  <CCard>
    <CCardHeader>
      {{ device.name }}({{ device.uuid }})
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CCard>
            <CCardHeader>
              Device Info
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <CInput
                    :value.sync="device.name"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-comment-bubble" />
                      &nbsp;Name
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="device.hostname"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-devices" />
                      &nbsp;Hostname
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CSelect
                    placeholder="Type"
                    :options="deviceTypeItem"
                    prepend="Type"
                    :value.sync="device.type"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="device.main_ip"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-router" />
                      &nbsp;Main IP
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="device.uuid"
                    disabled
                  >
                    <template #prepend-content>
                      <CIcon name="cil-tag" />
                      &nbsp;UUID
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton
                color="primary"
                square
                @click="editDevice"
              >
                Submit
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol sm="6">
          <CCard>
            <CCardHeader>
              Interface List
            </CCardHeader>
            <CCardBody>
              <CDataTable
                small
                fixed
                sorter
                :items-per-page="5"
                :fields="macListFields"
                :items="device.mac_list"
                :noItemsView="{ noItems: 'No Item~' }"
                pagination
                :sorterValue="{column: 'interface_name'}"
              >
                <template #action="{item}">
                  <td class="py-2">
                    <CButtonGroup>
                      <CButton
                        color="danger"
                        square
                        @click="deleteMacAddress(item.id)"
                      >
                        Delete
                      </CButton>
                    </CButtonGroup>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
            <CCardFooter>
              <CCardText>Add mac address</CCardText>
              <CRow>
                <CCol sm="12">
                  <CInput
                    :value.sync="interfaceName"
                    placeholder="ethx"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-comment-bubble" />
                      &nbsp;Interface Name
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CInput
                    :value.sync="macAddress"
                    placeholder="xx:xx:xx:xx:xx:xx"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-devices" />
                      &nbsp;Mac Address
                    </template>
                  </CInput>
                </CCol>
                <CCol sm="12">
                  <CButton
                    color="success"
                    square
                    @click="addMacAddress"
                  >
                    Add
                  </CButton>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="8">
              <CCard>
                <CCardHeader>
                  DHCP Address List
                </CCardHeader>
                <CCardBody>
                  <CDataTable
                    small
                    fixed
                    sorter
                    :items-per-page="5"
                    :fields="dhcpAddressListFields"
                    :items="device.dhcp_address"
                    :noItemsView="{ noItems: 'No Item~' }"
                    pagination
                    :sorterValue="{column: 'interface_name'}"
                  >
                    <template #routerName="{item}">
                      <td class="py-2">
                        {{ item.router.name }}
                      </td>
                    </template>
                    <template #macAddress="{item}">
                      <td class="py-2">
                        {{ item.mac.interface_name }}({{ item.mac.mac_address }})
                      </td>
                    </template>
                    <template #action="{item}">
                      <td class="py-2">
                        <CButtonGroup>
                          <CButton
                            color="info"
                            square
                            @click="requestMsg=item.uuid;requestMsgModal=true;"
                          >
                            Show UUID
                          </CButton>
                          <CButton
                            color="danger"
                            square
                            @click="deleteDeviceDhcpAddress(item.id)"
                          >
                            Delete
                          </CButton>
                        </CButtonGroup>
                      </td>
                    </template>
                  </CDataTable>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm="4">
              <CCard>
                <CCardHeader>
                  Add dhcp address
                </CCardHeader>
                <CCardBody>
                  <CSelect
                    label="Bind Router"
                    :options="routerListItem"
                    :value.sync='dhcpBindRouter'
                    @change="getRouterDhcpServer"
                  />
                  <CSelect
                    label="Bind DHCP Server"
                    :options="dhcpServerListItem"
                    :value.sync='dhcpBindServer'
                  />
                  <CSelect
                    label="Mac Address"
                    :options="macListItem"
                    :value.sync='dhcpBindMac'
                  />
                  <CInput
                    label="IP Address"
                    :value.sync='dhcpBindIp'
                  />
                </CCardBody>
                <CCardFooter>
                  <CButton
                    color="success"
                    square
                    @click="addDeviceDhcpAddress"
                  >
                    Add IP Address
                  </CButton>
                </CCardFooter>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
export default {
  name: "ViewDevice",
  data() {
    const macListFields = [
      { key: "interface_name", label: "Interface Name" },
      { key: "mac_address", label: "Mac Address" },
      {
        key: "action",
        label: "action",
        sorter: false,
        filter: false,
        _style: "width: 30%"
      }
    ];
    const dhcpAddressListFields = [
      { key: "routerName", label: "Bind Router" },
      { key: "macAddress", label: "Bind Interface" },
      { key: "ip", label: "IP Address" },
      {
        key: "action",
        label: "action",
        sorter: false,
        filter: false,
        _style: "width: 30%"
      }
    ];
    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      id: this.$route.params.id,
      device: [],
      deviceTypeItem:[],
      macListFields,
      interfaceName: null,
      macAddress: null,
      dhcpAddressListFields,
      routerListItem: [],
      dhcpBindRouter: null,
      dhcpServerListItem: [{ label: "Select a router first" }],
      dhcpBindServer: null,
      macListItem: [{label:"Add mac address first"}],
      dhcpBindMac: null,
      dhcpBindIp: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {
      this.$axios.get("user/cmdb/device/" + this.id + "/view", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.device = response.data.device;
            if (response.data.device.mac_list.length > 0) {
              this.macListItem = [{ label: "Select a mac address" }]
               response.data.device.mac_list.map(item => {
                 this.macListItem.push({ value: item.id, label: item.mac_address })
              });
            }
          }
        });

      this.$axios.get("user/cmdb/device/type", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.deviceTypeItem = response.data.type.map(item => {
              return {
                label: item.type_name,
                value: item.id
              }
            });
          }
        });

      if (this.routerListItem.length === 0) {
        this.routerListItem = [{ label: "Select a router" }];
        this.$axios.get("user/router", {
          params: {
            only_dhcp_router: "yes"
          }
        })
          .then((response) => {
            if (response.data.code === 200) {
              response.data.userRouter.map(item => {
                let label = item.name + "(" + item.host + ")";
                this.routerListItem.push({ value: item.id, label: label });
              });
            }
          });
      }

    },
    editDevice: function() {
      this.$axios.post("user/cmdb/device/" + this.id + "/edit", {
        name: this.device.name,
        type: this.device.type,
        hostname: this.device.hostname,
        main_ip: this.device.main_ip
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    addMacAddress: function() {
      this.$axios.post("user/cmdb/device/mac-address/add", {
        device_id: this.id,
        interface_name: this.interfaceName,
        mac_address: this.macAddress
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    deleteMacAddress: function(macAddressId) {
      this.$axios.delete("user/cmdb/device/mac-address/" + macAddressId + "/delete", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    getRouterDhcpServer: function() {
      this.$axios.get("user/router/" + this.dhcpBindRouter + "/dhcp-server", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.dhcpServerListItem = [{ label: "Select a dhcp server" }];
            response.data.dhcp_server.map(item => {
              let label;
              label = item.server_name + "(" + item.addresses + ")";
              this.dhcpServerListItem.push({value: item.id, label: label})
            });
            console.log(this.dhcpServerListItem)
          }
        });
    },
    addDeviceDhcpAddress: function() {
      this.$axios.post("user/cmdb/device/dhcp-address/add", {
        device_id: this.id,
        router_id: this.dhcpBindRouter,
        dhcp_server_id: this.dhcpBindServer,
        device_mac_id: this.dhcpBindMac,
        ip_address: this.dhcpBindIp
      })
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
    deleteDeviceDhcpAddress: function(dhcpAddressId) {
      this.$axios.delete("user/cmdb/device/dhcp-address/" + dhcpAddressId + "/delete", {})
        .then((response) => {
          if (response.data.code === 200) {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
            this.onLoadPage();
          } else {
            this.requestMsg = response.data.msg;
            this.requestMsgModal = true;
          }
        });
    },
  }
};
</script>